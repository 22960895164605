/* Import fonts from Google Fonts or any other source */
@import url('https://fonts.googleapis.com/css2?family=Arial:wght@400;700&family=Comic+Sans+MS&family=Courier+New&family=Georgia&family=Helvetica&family=Lucida+Sans&display=swap');

/* Define the Quill font styles */
.ql-font-arial {
  font-family: 'Arial', sans-serif;
}

.ql-font-comic-sans {
  font-family: 'Comic Sans MS', cursive;
}

.ql-font-courier-new {
  font-family: 'Courier New', monospace;
}

.ql-font-georgia {
  font-family: 'Georgia', serif;
}

.ql-font-helvetica {
  font-family: 'Helvetica', sans-serif;
}

.ql-font-lucida {
  font-family: 'Lucida Sans', sans-serif;
}
